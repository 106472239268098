import { css } from '@emotion/react'
import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  Ref,
  useEffect,
  useState,
} from 'react'
import {
  Modal,
  Overlay,
  OverlayContainer,
  OverlayTrigger,
} from '@conte-ltd/components-overlay'
import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { useAnimation } from '@conte-ltd/components-animation'
import { useDidUpdateEffect, useMediaQuery } from '@conte-ltd/components-utils'
import { useIsSSR } from '@react-aria/ssr'
import { Logo } from './logo'
import { Theme } from '../styles/theme'
import { Button } from './button'
import { Separator } from '@conte-ltd/components-separator'

interface PartOfMenuProps extends HTMLAttributes<HTMLSpanElement> {}

function _PartOfMenu(
  props: PartOfMenuProps,
  ref: ForwardedRef<HTMLSpanElement>
) {
  const { color } = useVariables<Theme>()
  return (
    <span
      css={css`
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        background: ${color.white};
      `}
      {...props}
      ref={ref}
    />
  )
}

const PartOfMenu = forwardRef(_PartOfMenu)

function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}

export function NavMenu() {
  const {
    color: themeColor,
    spacing,
    fontSize,
    fontWeight,
    letterSpacing,
    zIndex,
  } = useVariables<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)
  const isSSR = useIsSSR()
  const scrollPosition = useScrollPosition()
  const [isDark, setIsDark] = useState(false)
  const [color, setColor] = useState<string>(themeColor.white)

  useEffect(() => {
    const selectors = document.querySelectorAll(
      'main .dark-mode, main .light-mode'
    )

    selectors.forEach(element => {
      const rect = element.getBoundingClientRect()
      const isDarkMode = element.classList.contains('dark-mode')

      if (rect.top <= 0) {
        setIsDark(isDarkMode)
      }
    })

    if (document.body.classList.contains('dark-mode')) {
      setIsDark(true)
    } else if (document.body.classList.contains('light-mode')) {
      setIsDark(false)
    }
  }, [scrollPosition, isMobile])

  useEffect(() => {
    const color = isDark ? themeColor.white : themeColor.black
    setColor(color)
  }, [isDark])

  return isSSR ? (
    <></>
  ) : (
    <OverlayTrigger type="menu">
      {({ state, triggerProps, overlayProps, triggerRef, overlayRef }) => {
        const options = {
          duration: 150,
          fill: 'both',
        } as const
        const fadeOut = [{ opacity: 1 }, { opacity: 0 }]
        const fadeSlideOutUp = [
          { transform: 'rotate(-90deg)', opacity: 1 },
          { transform: 'rotate(-90deg)', opacity: 0 },
        ]
        const rotateTop = [
          {
            transformOrigin: 'center',
            transform: 'rotate(0deg) translate(0, 0)',
            opacity: 0,
          },
          {
            transformOrigin: 'center',
            transform: 'rotate(135deg)',
            opacity: 1,
          },
        ]
        const rotateBottom = [
          {
            transformOrigin: 'center',
            transform: 'rotate(0deg)',
            opacity: 0,
          },
          {
            transformOrigin: 'center',
            transform: 'rotate(-135deg)',
            opacity: 1,
          },
        ]

        const { ref, getAnimation } = useAnimation<HTMLElement>({
          autoPlay: false,
          options,
          keyframes: fadeOut,
        })

        const { ref: ref1, getAnimation: getAnimation1 } =
          useAnimation<HTMLElement>({
            autoPlay: false,
            options,
            keyframes: rotateTop,
          })

        const { ref: ref2, getAnimation: getAnimation2 } =
          useAnimation<HTMLElement>({
            autoPlay: false,
            options,
            keyframes: rotateBottom,
          })

        const { ref: ref3, getAnimation: getAnimation3 } =
          useAnimation<HTMLElement>({
            autoPlay: false,
            options,
            keyframes: fadeSlideOutUp,
          })

        useDidUpdateEffect(() => {
          const ani = getAnimation()
          const ani1 = getAnimation1()
          const ani2 = getAnimation2()
          const ani3 = getAnimation3()

          if (state.isOpen) {
            ani?.updatePlaybackRate(1)
            ani1?.updatePlaybackRate(1)
            ani2?.updatePlaybackRate(1)
            ani3?.updatePlaybackRate(1)
          } else {
            ani?.updatePlaybackRate(-1)
            ani1?.updatePlaybackRate(-1)
            ani2?.updatePlaybackRate(-1)
            ani3?.updatePlaybackRate(-1)
          }

          ani?.play()
          ani1?.play()
          ani2?.play()
          ani3?.play()
        }, [state.isOpen])

        return (
          <div
            css={css`
              display: flex;
              gap: ${spacing.offset.sm};
              transition: color 0.5s, opacity 1.5s ease-out;
            `}
            style={{
              color,
            }}
          >
            <div ref={ref as Ref<HTMLDivElement>}>
              <Logo
                fillColor={color}
                css={css`
                  width: 20rem;

                  @media ${bp.md} {
                    width: 14rem;
                  }
                `}
              />
            </div>

            <Button
              css={css`
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                display: inline-flex;
                flex-direction: column;
                justify-content: space-between;
                width: 1em;
                height: 1em;
                color: inherit;
                transition: unset;
              `}
              variant={'text'}
              onPress={state.open}
              ref={triggerRef}
              {...triggerProps}
            >
              <PartOfMenu ref={ref1} />
              <PartOfMenu ref={ref2} />
            </Button>

            <Button
              css={css`
                position: absolute;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: ${spacing.offset.sm};
                top: -5rem;
                right: calc(${spacing.offset.lg} * -1);
                transform: rotate(-90deg);
                transform-origin: right top;
                font-size: ${fontSize.sm};
                letter-spacing: ${letterSpacing.normal};
                color: inherit;
                transition: unset;

                @media ${bp.lg} {
                  top: -4.2rem;
                  font-size: ${fontSize.xs};
                }

                @media ${bp.md} {
                  top: -2.2rem;
                  flex-direction: column;
                  font-size: ${fontSize['2xs']};
                }
              `}
              variant={'text'}
              onPress={state.open}
              ref={ref3 as Ref<HTMLButtonElement | HTMLAnchorElement>}
              {...triggerProps}
            >
              <Separator
                css={css`
                  --c-separator-size: 3.3rem;

                  @media ${bp.md} {
                    --c-separator-size: 100%;
                  }
                `}
              />
              <span
                css={css`
                  @media ${bp.md} {
                    padding-right: 2rem;
                  }
                `}
              >
                MENU
              </span>
            </Button>

            <OverlayContainer
              css={css`
                z-index: ${zIndex.overlay};
              `}
              isPreventScroll
              isDismissable
              isOpen={state.isOpen}
              onClose={state.close}
              portalContainer={document.getElementById('header') ?? undefined}
              ref={overlayRef}
              {...overlayProps}
            >
              <Overlay
                css={css`
                  opacity: 0.8;
                `}
              />
              <Modal
                css={css`
                  background: transparent;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: ${spacing.xl};
                    padding: ${spacing.xs};
                    width: 100vw;
                    height: 100vh;
                    text-align: center;
                    white-space: nowrap;
                    color: ${themeColor.white};
                    overflow-y: scroll;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                      display: none;
                    }
                  `}
                >
                  <Logo
                    fillColor={themeColor.white}
                    css={css`
                      width: 20rem;

                      @media ${bp.lg} {
                        width: 14rem;
                      }
                    `}
                  />

                  <ul
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: ${spacing.md};
                      font-size: ${isMobile ? fontSize['2lg'] : fontSize.xl};
                      font-weight: ${fontWeight.semibold};
                    `}
                  >
                    {[
                      {
                        name: 'TOP',
                        href: '/',
                      },
                      {
                        name: 'ABOUT',
                        href: '/about',
                      },
                      {
                        name: 'WORKS',
                        href: '/works',
                      },
                      {
                        name: 'SERVICE',
                        href: '/service',
                      },
                      {
                        name: 'COMPANY',
                        href: '/#company',
                      },
                      {
                        name: 'CONTACT',
                        href: '/contact',
                      },
                    ].map(({ name, href }) => (
                      <li key={name}>
                        <Button
                          css={css`
                            color: inherit;
                          `}
                          variant={'text'}
                          href={href}
                          onPress={() => {
                            state.close()
                          }}
                        >
                          {name}
                        </Button>
                      </li>
                    ))}
                  </ul>

                  <ul
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: ${spacing.xs};
                      font-size: ${fontSize.sm};
                      font-weight: ${fontWeight.semibold};

                      @media ${bp.lg} {
                        font-size: ${fontSize.xs};
                      }
                    `}
                  >
                    {[
                      {
                        name: 'SITE POLICY',
                        href: '/site-policy',
                      },
                      {
                        name: 'PRIVACY POLICY',
                        href: '/privacy-policy',
                      },
                    ].map(({ name, href }) => (
                      <li key={name}>
                        <Button
                          css={css`
                            color: inherit;
                          `}
                          variant={'text'}
                          href={href}
                          onPress={() => {
                            state.close()
                          }}
                        >
                          {name}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Modal>
            </OverlayContainer>
          </div>
        )
      }}
    </OverlayTrigger>
  )
}
